<template>
	<a-result status="404" title="退订邮件" :sub-title="unsubscribeMessage">
		<template #extra>
			<router-link class="ant-btn ant-btn-primary" to="/">Back Home</router-link>
		</template>
	</a-result>
</template>

<script>
export default {
  name: "Unsubscribe",
  props: {
    email: {
      type: String,
      required: true
    }
  },
  computed: {
    unsubscribeMessage() {
      return `${this.email} 邮件已成功退订`;
    }
  }
}
</script>